import axios, { AxiosResponse } from "axios";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import ArrestDetail from "../interfaces/Detail/ArrestDetail.interface";
import InfocopDetail from "../interfaces/Detail/InfocopDetail.interface";
import InfocopImageDetail from "../interfaces/Detail/InfocopImageDetail.interface";
import ETicketDetail from "../interfaces/Detail/ETicketDetail.interface";
import ALPRDetail from "../interfaces/Detail/ALPRDetail.interface";
import CadRmsDetail from "../interfaces/Detail/CadRmsDetail.interface";
import ETraceDetail from "../interfaces/Detail/ETraceDetail.interface";
import { NJTraceObject } from "../interfaces/Detail/NJTraceDetail.interface";
import ParkMobileDetail from "../interfaces/Detail/ParkMobileDetail.interface";
import PrisonParoleDetail from "../interfaces/Detail/PrisonParoleDetail.interface";
import PrisonReleaseDetail from "../interfaces/Detail/PrisonReleaseDetail.interface";
import ProfessionDetail from "../interfaces/Detail/ProfessionDetail.interface";
import ViolentDetail from "../interfaces/Detail/ViolentDetail.interface";
import { DEVICELOCATION_URL, LINKANALYSIS, NJPOP, THREAT_API_URL } from "./config.service";
import { Account } from "../interfaces/auth_interface";
import { getUser } from "./auth.service";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";
import LPRDetail from "../interfaces/Detail/LPRDetail.interface";
import AuthenticatedDashboardAPIReq from "./AuthenticatedDashboardReq.service";
import TVInfo from "../interfaces/TVInfo.interface";

export async function RunArrestDetailQuery(ID: string | null, Dept: string | null): Promise<ArrestDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/Arrest/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}
export async function RunM43RecordArrestDetailQuery(ID: string | null, Dept: string | null): Promise<any[]> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/M43RecordArrest/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunInfocopDetailQuery(ID: string | null, Dept: string | null): Promise<InfocopDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/Infocop/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunInfocopImageDetailQuery(ID: string | null, Dept: string | null): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/Infocop_Image/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunETicketDetailQuery(ID: string | null, Dept: string | null): Promise<ETicketDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ETicket/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}
export async function RunTicketDetailQuery(ID: string | null, Dept: string | null): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/Ticket/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunShotSpotterQuery(ID: string | null, Dept: string | null): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ShotSpotter/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunALPRDetailQuery(ID: string | null, Dept: string | null, SystemCall: boolean | null = false): Promise<ALPRDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ALPR/?ID=' + ID + '&Dept=' + Dept + '&SystemCall=' + SystemCall)
    return (res.data.AnyData)
}

export async function RunLPRDetailQuery(ID: string | null, Dept: string | null): Promise<LPRDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ALPR/?ID=' + ID + '&Dept=' + Dept + '&SystemCall=false')
    return (res.data.AnyData)
}

export async function RunALPRDetailNo_IDQuery(ID: string | null, Dept: string | null): Promise<ALPRDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ALPR_NoID?ALPRItem=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunALPRImageQuery(ID: string | null, Dept: string | null, SystemCall: boolean | null = false): Promise<ALPRDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ALPR_Image/?ID=' + ID + '&Dept=' + Dept + '&SystemCall=' + SystemCall, { timeout: 30000 })
    return (res.data.AnyData)
}

export async function RunCadRmsDetailQuery(ID: string | null, Dept: string | null): Promise<CadRmsDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/CadRms/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}
export async function RunM43RecordRMSDetailQuery(ID: string | null, Dept: string | null): Promise<any[]> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/M43RecordRMS/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunETraceDetailQuery(ID: string | null, Dept: string | null): Promise<ETraceDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ETrace/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunNJTraceDetailQuery(ID: string | null, Dept: string | null): Promise<NJTraceObject> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/njtrace/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunParkMobileDetailQuery(ID: string | null, Dept: string | null): Promise<ParkMobileDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ParkMobile/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunPrisonParoleDetailQuery(ID: string | null, Dept: string | null): Promise<PrisonParoleDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/PrisonParole/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunPrisonReleaseDetailQuery(ID: string | null, Dept: string | null): Promise<PrisonReleaseDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/PrisonRelease/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunProfessionDetailQuery(ID: string | null, Dept: string | null): Promise<ProfessionDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/Profession/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunGunOffenderDetailQuery(ID: string | null, Dept: string | null): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/GunOffender/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunBOLODetailQuery(ID: string | null, Dept: string | null): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/BOLO/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunViolentDetailQuery(ID: string | null, Dept: string | null): Promise<ViolentDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/GetViolent/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunCodisDNADetailQuery(ID: string | null, Dept: string | null): Promise<ETicketDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/Codis/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunTowedVehicleDetailQuery(ID: string | null, Dept: string | null): Promise<TVInfo> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/TowedVehicleDetail/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function base64_to_blob(base64Data: any, contentType: any) {
    const base64Response = await fetch(`data:${contentType};base64,${base64Data}`);
    return await base64Response.blob();
}

export async function base64ToBlob(base64: string, contentType: string, sliceSize = 512) {
    try {
        const byteCharacters = atob(base64); // Decode base64
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    } catch (e) {
        console.log(e)
    }
}

export async function convertBlobToBase64(blob: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
    });
}

export async function OpenCodisPDF(ID: string | null, Dept: string | null) {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/CodisDNA/?ID=' + ID + '&Dept=' + Dept)
        if (res.data.AnyData != null) {
            base64_to_blob(res.data.AnyData.FileContents, res.data.AnyData.ContentType).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
    } catch (e) {
        console.error(e)
    }
}

export async function OpenSCORPDF(ID: any | null, Dept: string | null) {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/CrumbScorePDF/?ID=' + ID + '&Dept=' + Dept)
        if (res.data.AnyData != null) {
            base64_to_blob(res.data.AnyData.FileContents, res.data.AnyData.ContentType).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
    } catch (e) {
        console.error(e)
    }
}

export async function OpenReleaseNotesPDF(ID: any | null, Dept: string | null) {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ReleaseNotesPDF/?ID=' + ID + '&Dept=' + Dept)
        if (res.data.AnyData != null) {
            base64_to_blob(res.data.AnyData.FileContents, res.data.AnyData.ContentType).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
    } catch (e) {
        console.error(e)
    }
}

export async function OpenIndicatorPDF(ID: any | null, Dept: string | null) {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/IndicatorPDF/?ID=' + ID + '&Dept=' + Dept)
        if (res.data.AnyData != null) {
            base64_to_blob(res.data.AnyData.FileContents, res.data.AnyData.ContentType).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
    } catch (e) {
        console.error(e)
    }
}

export async function GetDeviceToken() {
    let user: Account = getUser();
    let res = await axios.post(DEVICELOCATION_URL + '/api/auth', {
        empID: user.EmpID,
        dept: user.Dept,
        otp: user.eOTP
    })

    return res.data
}

export function OpenNJPOPPDF(NJPOP_IncidentID: any) {
    let user: Account = getUser();
    window.open(NJPOP + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP + '&vNJPOP=' + NJPOP_IncidentID, '_blank')
}

export function OpenNIBINPDF(NIBIN_IncidentID: any) {
    let user: Account = getUser();
    window.open("/link_analysis?ReportID=" + NIBIN_IncidentID)
    window.open("/nibin_report_timeline?ReportID=" + NIBIN_IncidentID)
    window.open(NJPOP + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP + '&vNIBIN=' + NIBIN_IncidentID)
}
export function OpenNIBINTimeLine(NIBIN_IncidentID: any) {
    let user: Account = getUser();
    window.open("/nibin_report_timeline?ReportID=" + NIBIN_IncidentID)
}

export async function OpenUserManual(ID: any | null, Dept: string | null) {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/UserManualPDF/?ID=' + ID + '&Dept=' + Dept)
        if (res.data.AnyData != null) {
            base64_to_blob(res.data.AnyData.FileContents, res.data.AnyData.ContentType).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, "_self")
            })
        }
    } catch (e) {
        console.error(e)
    }
}

export async function GetALPRReportPDF(ID: string, Dept: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.get('/api/Detail/ALPR_VehicleReportPDF?ID=' + ID + '&Dept=' + Dept, { responseType: 'blob' });
    if (res != null) {
        const url = window.URL.createObjectURL(new File([res.data], "FileName.pdf", { type: 'application/pdf' }));
        return url;
    }
    return null;
}

export async function GetNIBINReport(reportId: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.get('/api/Dashboard/GetEtraceNibinReport?reportId=' + reportId, { responseType: 'blob' });
    if (res != null) {
        const url = window.URL.createObjectURL(new File([res.data], "FileName.pdf", { type: 'application/pdf' }));
        return url;
    }
    return null;
}

//export async function RunNJPOPDetailQuery(ID: string | null, Dept: string | null): Promise<NJPOPDetail> {
//    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/NJPOP/?ID=' + ID + '&Dept=' + Dept)
//    return (res.data.AnyData)
//}

//export async function RunNIBINDetailQuery(ID: string | null, Dept: string | null): Promise<NIBINDetail> {
//    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/NIBIN/?ID=' + ID + '&Dept=' + Dept)
//    return (res.data.AnyData)
//}